import React, { useState } from "react";
import { isAfter } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  Checkbox,
  CheckPicker,
  DateRangePicker,
  Input,
  SelectPicker,
  Tooltip,
} from "rsuite";
import moment from "moment";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import DatePicker from "react-datepicker";

const ControlPanelFilter2 = ({ period = false }) => {
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [fromdate, setFromDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [todate, setToDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [curDate, setCurDate] = useState(
    !period
      ? [
          new Date(moment().subtract(1, "d")),
          new Date(moment().subtract(1, "d")),
        ]
      : []
  );
  const [periodDuration, setPeriodDuration] = useState("week");
  const [month, setMonth] = useState();

  const [toolState, setToolState] = useState("");
  const { user } = useAuth();
  const reduxDate = useSelector((state) => state.date);

  const [stateTemp, setStateTemp] = useState([]);
  const [cityTemp, setCityTemp] = useState([]);
  const [assignmentsTemp, setAssignmentsTemp] = useState([]);

  useEffect(() => {
    let currDate = moment().format("YYYY-MM-DD");
    let firstDate = moment().date(1).format("YYYY-MM-DD");
    let seventhDate = moment().date(7).format("YYYY-MM-DD");
    let eighthDate = moment().date(8).format("YYYY-MM-DD");
    let forteenthDate = moment().date(14).format("YYYY-MM-DD");
    let fifteenth = moment().date(15).format("YYYY-MM-DD");
    let twentyFirstDate = moment().date(21).format("YYYY-MM-DD");
    let twentySecondDate = moment().date(22).format("YYYY-MM-DD");
    let LastDay = moment().endOf("month").format("YYYY-MM-DD");

    if (
      moment(currDate).isSame(firstDate) ||
      moment(currDate).isBefore(eighthDate)
    ) {
      setFromDate(moment().date(1).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(seventhDate) ||
        moment(currDate).isAfter(seventhDate)
      ) {
        setToDate(moment().date(7).format("YYYY-MM-DD"));
        setCurDate([new Date(moment().date(1)), new Date(moment().date(7))]);
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment().date(1)),
          new Date(moment().subtract(1, "d")),
        ]);
      }
    } else if (
      moment(currDate).isAfter(seventhDate) &&
      moment(currDate).isBefore(fifteenth)
    ) {
      setFromDate(moment().date(8).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(forteenthDate) ||
        moment(currDate).isAfter(forteenthDate)
      ) {
        setToDate(moment().date(14).format("YYYY-MM-DD"));
        setCurDate([new Date(moment().date(8)), new Date(moment().date(14))]);
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment().date(8)),
          new Date(moment().subtract(1, "d")),
        ]);
      }
    } else if (
      moment(currDate).isAfter(forteenthDate) &&
      moment(currDate).isBefore(twentySecondDate)
    ) {
      setFromDate(moment().date(15).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(twentyFirstDate) ||
        moment(currDate).isAfter(twentyFirstDate)
      ) {
        setToDate(moment().date(21).format("YYYY-MM-DD"));
        setCurDate([new Date(moment().date(15)), new Date(moment().date(21))]);
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment().date(15)),
          new Date(moment().subtract(1, "d")),
        ]);
      }
    } else if (
      moment(currDate).isAfter(twentyFirstDate) &&
      (moment(currDate).isBefore(LastDay) || moment(currDate).isSame(LastDay))
    ) {
      setFromDate(moment().date(22).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(LastDay) ||
        moment(currDate).isAfter(LastDay)
      ) {
        setToDate(moment().date(30).format("YYYY-MM-DD"));
        setCurDate([new Date(moment().date(22)), new Date(moment().date(30))]);
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment().date(22)),
          new Date(moment().subtract(1, "d")),
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (reduxDate) {
      setCustomerId(reduxDate.customerId);
      setFromDate(reduxDate.fromdate);
      setToDate(reduxDate.todate);
      setAssignments(reduxDate.assignments);
      setState(reduxDate.state);
      setCity(reduxDate.city);
      setCurDate(reduxDate.curDate);
      setPeriodDuration(reduxDate.period);
      setMonth(reduxDate.month);
    }
  }, [reduxDate]);

  console.log("reduxDate", reduxDate);

  useEffect(() => {
    if (user) {
      let state = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.state !== null)
            .map((item) => item.state)
        ),
      ];

      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.city !== null)
            .map((item) => item.city)
        ),
      ];

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.assignment_id !== null)
            .map((item) => item.assignment_id)
        ),
      ];
      setStateTemp(state);
      setCityTemp(city);
      setAssignmentsTemp(assignments);
    }
  }, [user]);

  useEffect(() => {
    if (state.length > 0) {
      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.city !== null && state.includes(item.state))
            .map((item) => item.city)
        ),
      ];
      setCityTemp(city);

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) => item.assignment_id !== null && city.includes(item.city)
            )
            .map((item) => item.assignment_id)
        ),
      ];

      setAssignmentsTemp(assignments);
    }
  }, [state]);

  useEffect(() => {
    if (city.length > 0) {
      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) => item.assignment_id !== null && city.includes(item.city)
            )
            .map((item) => item.assignment_id)
        ),
      ];
      setAssignmentsTemp(assignments);
    }
  }, [city]);

  const uniqueState = [
    ...new Map(
      user?.userdetails_v1
        ?.filter((item) => item.state != null)
        .map((item) => [item.state.trim(), item])
    ).values(),
  ].sort((a, b) => a.state.localeCompare(b.state));

  const uniqueCity = [
    ...new Map(
      user?.userdetails_v1
        ?.filter((item) => item.city != null && state.includes(item.state))
        .map((item) => [item.city.trim(), item])
    ).values(),
  ].sort((a, b) => a.city.localeCompare(b.city));

  const uniqueAssignments = [
    ...new Map(
      user?.userdetails_v1
        ?.filter(
          (item) => item.assignment_id != null && city.includes(item.city)
        )
        .map((item) => [item.assignment_id, item])
    ).values(),
  ].sort((a, b) => a.assignment_code.localeCompare(b.assignment_code));

  const handleSubmit = () => {
    dispatch({
      type: "SET_DATE",
      payload: {
        customerId: customerId,
        fromdate: fromdate,
        todate: todate,
        curDate: curDate,
        state: state,
        city: city,
        assignments: assignments,
        period: periodDuration,
        month: month,
      },
    });
  };

  const handleCheckAllStates = (value, checked) => {
    setState(checked ? stateTemp : []);
  };

  const handleCheckAllCity = (value, checked) => {
    setCity(checked ? cityTemp : []);
  };

  const handleCheckAllAssignments = (value, checked) => {
    setAssignments(checked ? assignmentsTemp : []);
  };

  const handleAttendanceDateWeek = (val) => {
    let currDate = moment().format("YYYY-MM-DD");
    let fromday = moment(val[0]).format("YYYY-MM-DD");
    let firstDate = moment(val[0]).date(1).format("YYYY-MM-DD");
    let seventhDate = moment(val[0]).date(7).format("YYYY-MM-DD");
    let eighthDate = moment(val[0]).date(8).format("YYYY-MM-DD");
    let forteenthDate = moment(val[0]).date(14).format("YYYY-MM-DD");
    let fifteenth = moment(val[0]).date(15).format("YYYY-MM-DD");
    let twentyFirstDate = moment(val[0]).date(21).format("YYYY-MM-DD");
    let twentySecondDate = moment(val[0]).date(22).format("YYYY-MM-DD");
    let LastDay = moment(val[0]).endOf("month").format("YYYY-MM-DD");

    if (
      moment(fromday).isSame(firstDate) ||
      moment(fromday).isBefore(eighthDate)
    ) {
      setFromDate(moment(val[0]).date(1).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(seventhDate) ||
        moment(currDate).isAfter(seventhDate)
      ) {
        setToDate(moment(val[1]).date(7).format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment(val[0]).date(1)),
          new Date(moment(val[1]).date(7)),
        ]);
      } else {
        setToDate(moment().format("YYYY-MM-DD"));
        setCurDate([new Date(moment(val[0]).date(1)), new Date(moment())]);
      }
    } else if (
      moment(fromday).isAfter(seventhDate) &&
      moment(fromday).isBefore(fifteenth)
    ) {
      setFromDate(moment(val[0]).date(8).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(forteenthDate) ||
        moment(currDate).isAfter(forteenthDate)
      ) {
        setToDate(moment(val[1]).date(14).format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment(val[0]).date(8)),
          new Date(moment(val[1]).date(14)),
        ]);
      } else {
        setToDate(moment().format("YYYY-MM-DD"));
        setCurDate([new Date(moment(val[0]).date(8)), new Date(moment())]);
      }
    } else if (
      moment(fromday).isAfter(forteenthDate) &&
      moment(fromday).isBefore(twentySecondDate)
    ) {
      setFromDate(moment(val[0]).date(15).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(twentyFirstDate) ||
        moment(currDate).isAfter(twentyFirstDate)
      ) {
        setToDate(moment(val[1]).date(21).format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment(val[0]).date(15)),
          new Date(moment(val[1]).date(21)),
        ]);
      } else {
        setToDate(moment().format("YYYY-MM-DD"));
        setCurDate([new Date(moment(val[0]).date(15)), new Date(moment())]);
      }
    } else if (
      moment(fromday).isAfter(twentyFirstDate) &&
      (moment(fromday).isBefore(LastDay) || moment(fromday).isSame(LastDay))
    ) {
      setFromDate(moment(val[0]).date(22).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(LastDay) ||
        moment(currDate).isAfter(LastDay)
      ) {
        setToDate(moment(val[1]).date(30).format("YYYY-MM-DD"));
        setCurDate([
          new Date(moment(val[0]).date(22)),
          new Date(moment(val[1]).date(30)),
        ]);
      } else {
        setToDate(moment().format("YYYY-MM-DD"));
        setCurDate([new Date(moment(val[0]).date(22)), new Date(moment())]);
      }
    }
  };

  const handleAttendanceDateMonth = (val) => {
    let currentMonth = moment().month();
    let valMonth = moment(val).month();



    if (valMonth === currentMonth) {
      setFromDate(moment(val).startOf("month").format("YYYY-MM-DD"));
      setToDate(moment().format("YYYY-MM-DD"));
      setCurDate([
        new Date(moment(moment(val).startOf("month").format("YYYY-MM-DD"))),
        new Date(moment(moment().format("YYYY-MM-DD"))),
      ]);
    } else {
      setFromDate(moment(val).startOf("month").format("YYYY-MM-DD"));
      setToDate(moment(val).endOf("month").format("YYYY-MM-DD"));
      setCurDate([
        new Date(moment(moment(val).startOf("month").format("YYYY-MM-DD"))),
        new Date(moment(moment(val).endOf("month").format("YYYY-MM-DD"))),
      ]);
    }
  };

  return (
    <div className="topBarStrip">
      <ul className="topBarStripCol6">
        <li>
          <CheckPicker
            data={
              uniqueState
                ? uniqueState.map((item) => {
                    return {
                      label: item.state.toUpperCase(),
                      value: item.state,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select State"
            value={state}
            onChange={(val) => {
              setState(val);
              setCity([]);
              setAssignments([]);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {stateTemp.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      customerId.length > 0 &&
                      customerId.length < stateTemp.length
                    }
                    checked={customerId.length === stateTemp.length}
                    onChange={handleCheckAllStates}
                  >
                    Select All States
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {state.length === stateTemp.length ? (
                      <span className="text-danger">All States Selected</span>
                    ) : (
                      ""
                    )}{" "}
                  </span>{" "}
                  {state.length !== stateTemp.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                  ,
                </span>
              );
            }}
          />
        </li>
        <li>
          {state.length === 0 && toolState === "city" ? (
            <Tooltip className="filterTooltip" visible>
              {"Please Select State"}
            </Tooltip>
          ) : (
            <></>
          )}
          <CheckPicker
            data={
              uniqueCity
                ? uniqueCity.map((item) => {
                    return {
                      label: item.city.toUpperCase(),
                      value: item.city,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select City"
            value={city}
            onChange={(val) => {
              setCity(val);
              setAssignments([]);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {state.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      city.length > 0 && city.length < cityTemp.length
                    }
                    checked={city.length === cityTemp.length}
                    onChange={handleCheckAllCity}
                  >
                    Select All Cities
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {city.length === cityTemp.length ? (
                      <span className="text-danger">All Cities Selected</span>
                    ) : (
                      ""
                    )}{" "}
                  </span>{" "}
                  {city.length !== cityTemp.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                  ,
                </span>
              );
            }}
            disabled={state.length === 0 ? true : false}
            onMouseOver={() => {
              setToolState("city");
            }}
            onMouseOut={() => {
              setToolState("");
            }}
          />
        </li>
        <li>
          {city.length === 0 && toolState === "assignments" ? (
            <Tooltip className="filterTooltip" visible>
              {"Please Select City"}
            </Tooltip>
          ) : (
            <></>
          )}
          <CheckPicker
            data={
              uniqueAssignments
                ? uniqueAssignments.map((item) => {
                    return {
                      label: `${item.assignment_code.toUpperCase()},
                             ${item.assignment_address}`,
                      value: item.assignment_id,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select Assignment"
            value={assignments}
            onChange={(val) => {
              setAssignments(val);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {city.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      assignments.length > 0 &&
                      assignments.length < assignmentsTemp.length
                    }
                    checked={assignments.length === assignmentsTemp.length}
                    onChange={handleCheckAllAssignments}
                  >
                    Select All Assignments
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {assignments.length === assignmentsTemp.length ? (
                      <span className="text-danger">
                        All Assignments Selected
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </span>{" "}
                  {assignments.length !== assignmentsTemp.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                  ,
                </span>
              );
            }}
            disabled={city.length === 0 ? true : false}
            onMouseOver={() => {
              setToolState("assignments");
            }}
            onMouseOut={() => {
              setToolState("");
            }}
          />
        </li>

        {period === true && (
          <li>
            <SelectPicker
              data={[
                { value: "month", label: "Month" },
                { value: "week", label: "Week" },
              ]}
              searchable={false}
              style={{ width: 224 }}
              placeholder="Select Period"
              value={periodDuration}
              onChange={(val) => {
                setPeriodDuration(val);
                setToDate();
                setFromDate();
                setCurDate([]);
                setMonth();
              }}
            />
          </li>
        )}

        {!period ? (
          <li>
            <DateRangePicker
              showOneCalendar
              cleanable={false}
              ranges={[]}
              disabledDate={(date) => isAfter(date, new Date())}
              placeholder="Select Date Range"
              value={curDate}
              onChange={(val) => {
                if (val == null) {
                  setFromDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
                  setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
                } else {
                  setFromDate(moment(val[0]).format("YYYY-MM-DD"));
                  setToDate(moment(val[1]).format("YYYY-MM-DD"));
                }
                setCurDate(val);
              }}
            />
          </li>
        ) : (
          <>
            {periodDuration === "week" ? (
              <li>
                <DateRangePicker
                  oneTap
                  showOneCalendar
                  cleanable={false}
                  ranges={[]}
                  disabledDate={(date) => isAfter(date, new Date())}
                  placeholder="Select Date Range"
                  value={curDate}
                  disabled={periodDuration ? false : true}
                  onChange={(val) => {
                    handleAttendanceDateWeek(val);
                  }}
                />
              </li>
            ) : (
              <li>
                <DatePicker
                  disabled={periodDuration ? false : true}
                  selected={month}
                  maxDate={new Date()}
                  onChange={(val) => {
                    setMonth(val);
                    handleAttendanceDateMonth(val);
                  }}
                  dateFormat="MM/yyyy"
                  className="monthPicker"
                  showMonthYearPicker
                />
              </li>
            )}
          </>
        )}

        <li>
          <button
            className="btn2"
            disabled={period && curDate.length === 0 ? true : false}
            onClick={() => {
              handleSubmit();
            }}
            type="button"
          >
            Submit
          </button>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ControlPanelFilter2);
