import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("userData", null);
  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);

    console.log("data",data);

    if(data.returnUrl=="DASHBOARD"){
      navigate("/", { replace: true });
    }else if (data.returnUrl==="Reports_Contract_Get"){ 
     navigate("/reports/attendance/contracted",{replace:true});
    }else if(data.returnUrl==="Reports_Performed_Get"){
      navigate("/reports/attendance/performed",{replace:true});
    } else if(data.returnUrl==="Reports_AdditionalPerformed_Get"){
      navigate("/reports/attendance/additional-performed",{replace:true});
    } else if(data.returnUrl==="Reports_Unperformed_Get"){
      navigate("/reports/attendance/unperformed",{replace:true});
    } else if(data.returnUrl==="Reports_AttendanceApproval_Get"){
      navigate("/reports/attendance-approval",{replace:true});
    } else if(data.returnUrl==="Reports_AttendanceApprovalV1_Get"){
      navigate("/reports/attendance-approval-v1",{replace:true});
    } else if(data.returnUrl==="Reports_LocationException_Get"){
      navigate("/reports/location-exception",{replace:true});
    } else if(data.returnUrl==="Reports_Manpower_Get"){
      navigate("/reports/manpower-overview",{replace:true});
    } else if(data.returnUrl==="Reports_Inspection_Get"){
      navigate("/reports/inspection",{replace:true});
    } else if(data.returnUrl==="Reports_IncidentReported_Get"){
      navigate("/reports/incident-reported",{replace:true});
    } else if(data.returnUrl==="Reports_SitePatrolling_TourReportsSubmit"){
      navigate("/reports/site-patrolling",{replace:true});
    } else if(data.returnUrl==="Reports_Leaves_Get"){
      navigate("/reports/leave",{replace:true});
    }

    // navigate("/", { replace: true });
  };

  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
