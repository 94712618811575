import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedLayout from "./components/ProtectedLayout";
import HomeLayout from "./components/HomeLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import "react-circular-progressbar/dist/styles.css";
import "./style/App.css";
import "./style/menu.css";
import "react-datepicker/dist/react-datepicker.css";
import { lazy, Suspense, useEffect } from "react";
import { ImpulseSpinner } from "react-spinners-kit";
import Cookies from "js-cookie";
import api from "./lib/api";
import { useAuth } from "./hooks/useAuth";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { useCallback } from "react";
import {} from "react-leaflet";
import AttendanceApprovalV1 from "./pages/reports/AttendanceApprovalV1";
import Leave from "./pages/reports/Leave";

const loader = (
  <div
    style={{
      background: "#fff",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: "9999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
  </div>
);

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Contracted = lazy(() => import("./pages/reports/attendance/Contracted"));
const Performed = lazy(() => import("./pages/reports/attendance/Performed"));
const AdditionalPerformed = lazy(() =>
  import("./pages/reports/attendance/AdditionalPerformed")
);
const Unperformed = lazy(() =>
  import("./pages/reports/attendance/Unperformed")
);
const Inspection = lazy(() => import("./pages/reports/Inspection"));
const InspectionDetails = lazy(() =>
  import("./pages/reports/InspectionDetails")
);
const MapPowerOverview = lazy(() => import("./pages/reports/ManPowerOverview"));
const IncidentReported = lazy(() => import("./pages/reports/IncidentReported"));
const LocationException = lazy(() =>
  import("./pages/reports/LocationException")
);
const AttendanceApproval = lazy(() =>
  import("./pages/reports/AttendanceApproval")
);
const SitePatrolling = lazy(() => import("./pages/reports/SitePatrolling"));

const Page404 = lazy(() => import("./pages/Page404"));

const NotAuthorize = lazy(() => import("./pages/NotAuthorize"));

export default function App() {
  const timeOut = 1000 * 60 * 20;
  const { logout, user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTokenUpdate = () => {
    if (Cookies.get("myToken")) {
      api
        .post("/token_update", { inputtoken: Cookies.get("myToken") })
        .then((res) => Cookies.set("myToken", res.data.token))
        .catch((err) => {
          if (Cookies.get("myToken")) {
            Cookies.remove("myToken");
            localStorage.removeItem("userData");
          }
        });
    }
  };

  useEffect(() => {
    if (user) {
      handleTokenUpdate();
    }
  }, [user]);

  setInterval(handleTokenUpdate, 1000 * 60 * 10);

  const handleLogOut = () => {
    if (localStorage.getItem("userData")) {
      Cookies.remove("myToken");
      localStorage.removeItem("userData");
      logout();
      dispatch({
        type: "SET_DATE",
        payload: null,
      });
      navigate("/login");
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: timeOut,
    onIdle: handleLogOut,
    // onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <Suspense fallback={loader}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/reports/attendance/contracted"
            element={<Contracted />}
          />
          <Route path="/reports/attendance/performed" element={<Performed />} />
          <Route
            path="/reports/attendance/additional-performed"
            element={<AdditionalPerformed />}
          />
          <Route
            path="/reports/attendance/unperformed"
            element={<Unperformed />}
          />
          <Route path="/reports/inspection" element={<Inspection />} />
          <Route
            path="/reports/inspection-details"
            element={<InspectionDetails />}
          />
          <Route
            path="/reports/manpower-overview"
            element={<MapPowerOverview />}
          />
          <Route
            path="/reports/incident-reported"
            element={<IncidentReported />}
          />
          <Route
            path="/reports/location-exception"
            element={<LocationException />}
          />
          <Route
            path="/reports/attendance-approval"
            element={<AttendanceApproval />}
          />
          <Route
            path="/reports/attendance-approval-v1"
            element={<AttendanceApprovalV1 />}
          />
          <Route
            path="/reports/leave"
            element={<Leave />}
          />
          <Route path="/reports/site-patrolling" element={<SitePatrolling />} />
          <Route path="not-authorize" element={<NotAuthorize />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
