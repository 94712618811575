import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { DatePicker, Input, Modal } from "rsuite";
import SaveApproveModal from "./AttendanceApprovalModal/SaveApproveModal";
import api from "../lib/api";
import { useAuth } from "../hooks/useAuth";

const LeaveViewModal = ({
  viewModalOpen,
  setViewModalOpen,
  empData,
  setEmpData,
  refetchData,
  ip,
}) => {
  const [imagePath, setImagePath] = useState("");
  const [comment, setComment] = useState("");
  const [markInTime, setMarkInTime] = useState();
  const [markOutTime, setMarkOutTime] = useState();
  const [validateData, setValidateData] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [dateAltered, setDateAltered] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const { user } = useAuth();
  const accessDetails = JSON.parse(user?.accessDetails);

  useEffect(() => {
    if (empData?.Image && empData?.Image !== "" && viewModalOpen === true) {
      getImage({
        filename: empData?.Image,
      });
    } else {
      setImagePath();
    }
    // eslint-disable-next-line no-use-before-define
  }, [viewModalOpen, empData?.Image]);

  useEffect(() => {
    if (empData) {
      let approveInTemp = new Date(empData?.ApprovedIn);
      approveInTemp.setSeconds(0);
      let approveOutTemp = new Date(empData?.ApprovedOut);
      approveOutTemp.setSeconds(0);

      setMarkInTime(approveInTemp);
      setMarkOutTime(approveOutTemp);
      setComment(empData.Comments);
    }
  }, [empData]);

  const { mutate: getImage, isLoading } = useMutation(
    (data) => api.post("/CustomerLeaveReport/GetImage", data), // for iconnect portal
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
          setImagePath("");
        } else {
          setImagePath(res.data);
        }
      },
    }
  );

  //   const { mutate: editSave, isLoading: editSaveLoading } = useMutation(
  //     (data) => api.post("CustomerAttendanceApproval_V1/Set", data),
  //     {
  //       onSuccess: (res) => {
  //         setApproveModalOpen(false);
  //         setViewModalOpen(false);
  //         if (res.data.response === "Failed") {
  //           cogoToast.error(res.data.errorMsg);
  //         } else {
  //           cogoToast.success(`Record Saved Successfully`);
  //           setEmpData();
  //           setValidateData();
  //           setMarkInTime();
  //           setMarkOutTime();
  //           setComment("");
  //           setDateAltered(false);
  //           refetchData();
  //           setValidationMsg("");
  //         }
  //       },
  //     }
  //   );

  //   const save = () => {
  //     if (dateAltered === true) {
  //       if (comment !== "") {
  //         editSave({
  //           user_id: 1,
  //           id: JSON.stringify(empData?.id),
  //           action: "Validated",
  //           modifiedBy_IP: ip,
  //           approvedIn: moment(markInTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
  //           approvedOut: moment(markOutTime).isAfter(markInTime)
  //             ? moment(markOutTime).format("YYYY-MM-DD HH:mm:ss.SSS")
  //             : moment(markOutTime).add(1, "d").format("YYYY-MM-DD HH:mm:ss.SSS"),
  //           ApprovedHrs: moment
  //             .utc(moment(markOutTime, "HH:mm").diff(moment(markInTime, "HH:mm")))
  //             .add(1, "second")
  //             .format("HH:mm"),
  //           // comments: comment,

  //         // id: transId,

  //         validatedBy_IP: "117.199.26.41",
  //         regularHrs: "",
  //         otHrs: "",
  //         comments: "",
  //         totalPerfHrs: "",

  //         });
  //       } else {
  //         setValidationMsg("This Field Is Required");
  //       }
  //     } else {
  //       editSave({

  //         user_id: 1,
  //         id: JSON.stringify(empData?.id),
  //         action: "EditSave",
  //         modifiedBy_IP: ip,
  //         approvedIn: moment(markInTime).format("YYYY-MM-DD HH:mm:ss.SSS"),
  //         approvedOut: moment(markOutTime).isAfter(markInTime)
  //           ? moment(markOutTime).format("YYYY-MM-DD HH:mm:ss.SSS")
  //           : moment(markOutTime).add(1, "d").format("YYYY-MM-DD HH:mm:ss.SSS"),
  //           ApprovedHrs: moment
  //           .utc(moment(markOutTime, "HH:mm").diff(moment(markInTime, "HH:mm")))
  //           .add(1, "second")
  //           .format("HH:mm"),
  //         comments: comment,

  //         validatedBy_IP: "117.199.26.41",
  //         regularHrs: "",
  //         otHrs: "",
  //         comments: "",
  //         totalPerfHrs: "",
  //       });
  //     }
  //   };

  //   const ValiDate = () => {
  //     if (dateAltered === true) {
  //       if (comment?.length > 0) {
  //         setValidateData({
  //           user_id: user?.userid,
  //           id: JSON.stringify(empData?.id),
  //           action: "EditApprove",
  //           modifiedBy_IP: ip,
  //           modifiedBy_Address: "",
  //           approvedIn: markInTime,
  //           approvedOut: markOutTime,
  //           ApprovedHrs: moment
  //             .utc(moment(markOutTime, "HH:mm").diff(moment(markInTime, "HH:mm")))
  //             .add(1, "second")
  //             .format("HH:mm"),
  //           comments: comment,
  //         });
  //         setApproveModalOpen(true);
  //       } else {
  //         setValidationMsg("This Field Is Required");
  //       }
  //     } else {
  //       setValidateData({
  //         user_id: user?.userid,
  //         id: JSON.stringify(empData?.id),
  //         action: "EditApprove",
  //         modifiedBy_IP: ip,
  //         modifiedBy_Address: "",
  //         approvedIn: markInTime,
  //         approvedOut: markOutTime,
  //         ApprovedHrs: moment
  //           .utc(moment(markOutTime, "HH:mm").diff(moment(markInTime, "HH:mm")))
  //           .add(1, "second")
  //           .format("HH:mm"),
  //         comments: comment,
  //       });
  //       setApproveModalOpen(true);
  //     }
  //   };

  console.log("empData leave view modal", empData);

  return (
    <Modal
      backdrop="static"
      className="modalFormSec attendApprDtlMdl attendApprDtlMdl2 attendanceDetailModal reportImgDetail qrCodeViewSec"
      size="md"
      open={viewModalOpen}
      onClose={() => {
        setViewModalOpen(false);
        setEmpData();
        setMarkInTime();
        setMarkOutTime();
        setComment();
        setDateAltered(false);
        setValidationMsg("");
      }}
    >
      <Modal.Header>
        <Modal.Title>Employee Leave Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container row">
          <div className="row">
            <SaveApproveModal
              approveModalOpen={approveModalOpen}
              setApproveModalOpen={setApproveModalOpen}
              setViewModalOpen={setViewModalOpen}
              approvedData={validateData}
              setEmpData={setEmpData}
              refetchData={refetchData}
              setApproveData={setValidateData}
              setComment={setComment}
              setDateAltered={setDateAltered}
              setValidationMsg={setValidationMsg}
            />
            <div className="col-md-3">
              <figure>
                <h5> Employee Image </h5>
                <img
                  src={
                    imagePath
                      ? `data:image/jpeg;base64,${imagePath}`
                      : "../../../../images/icons/user/profile.svg"
                  }
                  alt="employee"
                  width={100}
                  height={100}
                />
              </figure>
            </div>
            <div className="col-md-9">
              <table className="text-left">
                <tbody>
                  {/* <tr>
                    <th colSpan={3}>
                      <h4 className="text-danger pdt_0">Contract</h4>
                    </th>
                  </tr> */}
                  <tr>
                    <td>State</td>
                    <td>:</td>
                    <td>{empData?.State}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>:</td>
                    <td>{empData?.city}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>:</td>
                    <td>{empData?.assignment_address}</td>
                  </tr>
                  <tr>
                    <td> Emp Code</td>
                    <td>:</td>
                    <td>{empData?.EmpCode}</td>
                  </tr>
                  <tr>
                    <td>Emp Name</td>
                    <td>:</td>
                    <td>{empData?.employee_name}</td>
                  </tr>
                  <tr>
                    <td>Rank</td>
                    <td>:</td>
                    <td>{empData?.designation}</td>
                  </tr>
                  <tr>
                    <td>Mobile Number</td>
                    <td>:</td>
                    <td>{empData?.mobile}</td>
                  </tr>
                  <tr>
                    <td>Applied Date</td>
                    <td>:</td>
                    <td>{moment(empData?.AppliedDate).format("DD-MM-y")}</td>
                  </tr>
                  <tr>
                    <td>Applied Time</td>
                    <td>:</td>
                    <td>{empData?.AppliedTime?.slice(0,5)}</td>
                  </tr>

                  <tr>
                    <td>Leave Type</td>
                    <td>:</td>
                    <td>{empData?.LeaveType}</td>
                  </tr>
                  <tr>
                    <td>Leave Start Date</td>
                    <td>:</td>
                    <td>{moment(empData?.FromDate).format("DD-MM-y")}</td>
                  </tr>
                  <tr>
                    <td>Leave End Date</td>
                    <td>:</td>
                    <td>{moment(empData?.ToDate).format("DD-MM-y")}</td>
                  </tr>
                  <tr>
                    <td>No of Leave Days</td>
                    <td>:</td>
                    <td> {empData?.NoOfDays}</td>
                  </tr>
                  <tr>
                    <td> Leave Status</td>
                    <td>:</td>
                    <td> {empData?.LeaveStatus}</td>
                  </tr>
                  <tr>
                    <td> Reason</td>
                    <td>:</td>
                    {/* <td> {empData?.ApprovedDutyHrs}</td> */}
                    <td> {empData?.Reason}</td>
                  </tr>
                  <tr>
                    <td> Leave Balance (PL)</td>
                    <td>:</td>
                    <td> {empData?.pl_balance}</td>
                  </tr>
                  <tr>
                    <td> Leave Balance(CL)</td>
                    <td>:</td>
                    <td> {empData?.cl_balance}</td>
                  </tr>
                  <tr>
                    <td> OO Name</td>
                    <td>:</td>
                    <td> {empData?.OO_Name}</td>
                  </tr>
                  <tr>
                    <td>OM Name</td>
                    <td>:</td>
                    <td>{empData?.OM_Name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(LeaveViewModal);
