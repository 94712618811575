import React, { useState, useEffect } from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import {
  SelectPicker,
  Whisper,
  Tooltip,
  Checkbox,
  Modal,
  DatePicker,
  Input,
  Loader,
  CheckboxGroup,
} from "rsuite";
import moment from "moment";
import api from "../../lib/api";
import { useSelector } from "react-redux";
import { ImpulseSpinner } from "react-spinners-kit";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import ControlFilter from "../../components/ControlFilter";
import ControlPanelFilter from "../../components/ControlPanelFilter";
import { useAuth } from "../../hooks/useAuth";
import { changeTimeFormat } from "../../lib/time";
import {
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaPencilAlt,
  FaRegTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import AttendanceDetailsModalV1 from "../../components/AttendanceApprovalModalV1/AttendanceDetailsModalV1";
import AttendanceDetailsViewModalV1 from "../../components/AttendanceApprovalModalV1/AttendanceDetailsViewModalV1";
import AttendanceDetailsModalAddNew from "../../components/AttendanceApprovalModalV1/AttendanceDetailsModalAddNew";
import ControlPanelFilter2 from "../../components/ControlPanelFilter2";
import ControlFilter2 from "../../components/ControlFilter2";
import axios from "axios";
import ControlPanelFilterNew from "../../components/ControlPanelFilterNew";

const searchBy = [
  { value: 1, label: "State" },
  {
    value: 2,
    label: "City",
  },

  {
    value: 3,
    label: "Duty Date",
  },
  { value: 4, label: "Post" },

  { value: 5, label: "Rank" },
  {
    value: 6,
    label: "Shift Code",
  },
  {
    value: 7,
    label: "Employee Code",
  },
  {
    value: 8,
    label: "Employee Name",
  },

  {
    value: 9,
    label: "Scheduled",
  },
  {
    value: 10,
    label: "Edited By",
  },

  {
    value: 11,
    label: "Attend Type",
  },
  {
    value: 12,
    label: "Status",
  },
];

const AttendanceApprovalV1 = () => {
  const [customerId, setCustomerId] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [fromdate, setFromDate] = useState();
  const [todate, setToDate] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const { user } = useAuth();
  const reduxData = useSelector((state) => state.date);

  const [type, setType] = useState("All");
  const [searchById, setSearchById] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchButton, setSearchButton] = useState(false);

  const [allTransId, setAllTransId] = useState([]);
  const [transId, setTransId] = useState([]);

  const [empData, setEmpData] = useState();
  const [rejectId, setRejectId] = useState();
  const [approvedId, setApprovedId] = useState();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [AddNewModal, setAddNewModal] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [singleApproveModal, setSingleApproveModal] = useState(false);
  const [multipleApproveModal, setMultipleApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [multipleSubmitModal, setMultipleSubmitModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [justificationModal, setJustificationModal] = useState(false);

  const [attendanceApprovalData, setAttendanceApprovalData] = useState(0);
  const [contractData, setContractData] = useState();
  const [performedData, setPerformedData] = useState();
  const [ip, setIp] = useState();
  const [extension, setExtension] = useState();

  // for edit validation
  const [justification, setJustification] = useState("");
  const [regularHrs, setRegularHrs] = useState();
  const [tempRegularHrsChkTime, setTempRegularHrsChkTime] = useState();
  const [otHrs, setOtHrs] = useState();
  const [tempOtHrsChkTime, setTempOtHrsChkTime] = useState();
  const [validateData, setValidateData] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [dateAltered, setDateAltered] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isTimeChange, setIsTimeChange] = useState();
  const [hour, setHour] = useState();
  const [hourTwo, setHourTwo] = useState();

  const accessDetails = JSON.parse(user?.accessDetails);

  const { data: ipData, isLoading: ipLoading } = useQuery(
    ["ipAddress"],
    () => axios.get("https://geolocation-db.com/json/"),
    {
      onSuccess: (res) => {
        setIp(res.data.IPv4);
      },
    }
  );

  useEffect(() => {
    if (user && user?.userdetails_v1 !== null && !reduxData && fromdate) {
      getAttendanceApprovalReport({
        page: page,
        size: 10,
        sort: "",
        totalrow: 0,
        state:
          user?.sel_cust === "A" && state.length === 0
            ? stateArray.join(":")
            : state.join(":"),
        city:
          user?.sel_cust === "A" && city.length === 0
            ? cityArray.join(":")
            : city.join(":"),
        assignments:
          user?.sel_cust === "A" && assignments.length === 0
            ? assignmentIdArray.join(":")
            : assignments.join(":"),
        fromdate: fromdate,
        todate: todate,
        userid: user?.userid,
        paginationstatus: "Y",
        Action: type,
        searchbyid: searchById,
        searchtext: searchTerm,
        assignment_status: "N",
      });
    }
  }, [fromdate, page, type, searchButton]);

  useEffect(() => {
    let currDate = moment().format("YYYY-MM-DD");
    let firstDate = moment().date(1).format("YYYY-MM-DD");
    let seventhDate = moment().date(7).format("YYYY-MM-DD");
    let eighthDate = moment().date(8).format("YYYY-MM-DD");
    let forteenthDate = moment().date(14).format("YYYY-MM-DD");
    let fifteenth = moment().date(15).format("YYYY-MM-DD");
    let twentyFirstDate = moment().date(21).format("YYYY-MM-DD");
    let twentySecondDate = moment().date(22).format("YYYY-MM-DD");
    let LastDay = moment().endOf("month").format("YYYY-MM-DD");

    if (
      moment(currDate).isSame(firstDate) ||
      moment(currDate).isBefore(eighthDate)
    ) {
      setFromDate(moment().date(1).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(seventhDate) ||
        moment(currDate).isAfter(seventhDate)
      ) {
        setToDate(moment().date(7).format("YYYY-MM-DD"));
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
      }
    } else if (
      moment(currDate).isAfter(seventhDate) &&
      moment(currDate).isBefore(fifteenth)
    ) {
      setFromDate(moment().date(8).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(forteenthDate) ||
        moment(currDate).isAfter(forteenthDate)
      ) {
        setToDate(moment().date(14).format("YYYY-MM-DD"));
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
      }
    } else if (
      moment(currDate).isAfter(forteenthDate) &&
      moment(currDate).isBefore(twentySecondDate)
    ) {
      setFromDate(moment().date(15).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(twentyFirstDate) ||
        moment(currDate).isAfter(twentyFirstDate)
      ) {
        setToDate(moment().date(21).format("YYYY-MM-DD"));
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
      }
    } else if (
      moment(currDate).isAfter(twentyFirstDate) &&
      (moment(currDate).isBefore(LastDay) || moment(currDate).isSame(LastDay))
    ) {
      setFromDate(moment().date(22).format("YYYY-MM-DD"));
      if (
        moment(currDate).isSame(LastDay) ||
        moment(currDate).isAfter(LastDay)
      ) {
        setToDate(moment().date(30).format("YYYY-MM-DD"));
      } else {
        setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
      }
    }
  }, []);

  useEffect(() => {
    if (reduxData) {
      setPage(0);
      setCustomerId(reduxData.customerId);
      setState(reduxData.state);
      setCity(reduxData.city);
      setAssignments(reduxData.assignments);
      setFromDate(reduxData.fromdate);
      setToDate(reduxData.todate);
      setFilterData({
        customerid: customerId,
        state: reduxData.state,
        city: reduxData.city,
        assignments: reduxData.assignments,
        fromdate: reduxData.fromdate,
        todate: reduxData.todate,
      });
      setMessageModal(true);
    }
  }, [reduxData]);

  console.log("reduxData", reduxData);

  console.log("customerId", customerId);
  console.log("state", state);

  useEffect(() => {
    if (Object.keys(filterData).length !== 0) {
      getAttendanceApprovalReport({
        page: page,
        size: 10,
        sort: "",
        totalrow: 0,
        state: state.join(":"),
        city: city.join(":"),
        Assignments: assignments.join(":"),
        Fromdate: fromdate ? fromdate : "",
        Todate: todate ? todate : "",
        userid: user?.userid,
        paginationstatus: "Y",
        Action: type,
        searchbyid: searchById,
        searchtext: searchTerm,
        assignment_status: "N",
      });
    }
  }, [filterData, page, type, searchButton]);

  const refetchData = () => {
    if (Object.keys(filterData).length !== 0) {
      getAttendanceApprovalReport({
        page: page,
        size: 10,
        sort: "",
        totalrow: 0,
        state: state.join(":"),
        city: city.join(":"),
        Assignments: assignments.join(":"),
        Fromdate: fromdate ? fromdate : "",
        Todate: todate ? todate : "",
        userid: user?.userid,
        paginationstatus: "Y",
        Action: type,
        customerid: 0,
        searchbyid: searchById,
        searchtext: searchTerm,
        assignment_status: "N",
      });
    }
    if (user && user?.userdetails_v1 !== null && !reduxData) {
      getAttendanceApprovalReport({
        page: page,
        size: 10,
        sort: "",
        totalrow: 0,
        state:
          user?.sel_cust === "A" && state.length === 0
            ? stateArray.join(":")
            : state.join(":"),
        city:
          user?.sel_cust === "A" && city.length === 0
            ? cityArray.join(":")
            : city.join(":"),
        Assignments:
          user?.sel_cust === "A" && assignments.length === 0
            ? assignmentIdArray.join(":")
            : assignments.join(":"),
        Fromdate: fromdate,
        Todate: todate,
        userid: user?.userid,
        paginationstatus: "Y",
        Action: type,
        customerid: 0,
        searchbyid: searchById,
        searchtext: searchTerm,
        assignment_status: "N",
      });
    }
  };

  // Custom order of indices
  let customOrder2 = {
    All: 1,
    Pending: 2,
    Validated: 3,
    Approved: 4,
    "Edited&Approved": 5,
  };

  const statusFilter = [
    ...new Map(
      accessDetails
        ?.filter(
          (item) => item.option_name === "Reports_AttendanceApprovalV1_Get"
        )
        .map((item) => [item?.action_name, item])
    ).values(),
  ];

  // console.log("statusFilter", statusFilter);
  // console.log("accessDetails", accessDetails);

  let newStatusFilter = statusFilter
    .filter((item) => item !== undefined)
    .sort(function (a, b) {
      return customOrder2[a.action_name] - customOrder2[b.action_name];
    });

  const stateArray = [
    ...new Set(user?.userdetails_v1?.map((item) => item.state)),
  ];
  const cityArray = [
    ...new Set(user?.userdetails_v1?.map((item) => item.city)),
  ];
  const assignmentIdArray = [
    ...new Set(user?.userdetails_v1?.map((item) => item.assignment_id)),
  ];

  const { mutate: getAttendanceApprovalReport, isLoading } = useMutation(
    (data) => api.post("/CustomerAttendanceApproval_V1/Get", data),
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          if (res.data.ApprovedAttendance === "") {
            setAttendanceApprovalData(0);
            setCount(0);
            setTransId([]);
            setAllTransId([]);
          } else {
            setAttendanceApprovalData(JSON.parse(res.data.ApprovedAttendance));
            setCount(JSON.parse(res.data.AttendanceCount));
            setTransId([]);
            let temp = JSON.parse(res.data.ApprovedAttendance).map((item) => {
              return item;
            });

            let temp2 = temp
              .filter((i) => {
                return type === i.status;
              })
              .map((val) => {
                return val.id;
              });

            setAllTransId(temp2);
          }
        }
      },
    }
  );

  const handleCheckAll = (value, checked) => {
    setTransId(checked ? allTransId : []);
  };

  const singleCheck = (value, checked) => {
    if (checked === true) {
      setTransId((prevState) => [...prevState, value]);
    } else if (checked === false) {
      setTransId((current) =>
        current.filter((obj) => {
          return obj !== value;
        })
      );
    }
  };

  const handlePageChange = (val) => {
    if (val === "plus" && page < Math.ceil(count / 10) - 1) {
      setPage(page + 1);
    } else if (val === "minus" && page > 0) {
      setPage(page - 1);
    }
  };

  const { mutate: getFileName, isLoading: getFileLoading } = useMutation(
    (data) => api.post("CustomerAttendanceApproval_V1/DownloadReport", data),
    {
      onSuccess: (res) => {
        const linkSource =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          res.data;
        const downloadLink = document.createElement("a");
        const fileName = `AttendanceApproval${Date.now()}.${
          extension.split("-")[0]
        }`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
    }
  );

  useEffect(() => {
    if (extension) {
      getFileName({
        // sort: "",
        // totalrow: 0,
        // state:
        //   user?.sel_cust === "A" && state.length === 0
        //     ? stateArray.join(":")
        //     : state.join(":"),
        // city:
        //   user?.sel_cust === "A" && city.length === 0
        //     ? cityArray.join(":")
        //     : city.join(":"),
        // assignments:
        //   user?.sel_cust === "A" && assignments.length === 0
        //     ? assignmentIdArray.join(":")
        //     : assignments.join(":"),
        // fromdate: fromdate,
        // todate: todate,
        // userid: user?.userid,
        // paginationstatus: "N",
        // assignment_status: "N",
        // Action: type,
        // extension: extension.split("-")[0],

        sort: "",
        totalrow: 0,
        state:
          user?.sel_cust === "A" && state.length === 0
            ? stateArray.join(":")
            : state.join(":"),
        city:
          user?.sel_cust === "A" && city.length === 0
            ? cityArray.join(":")
            : city.join(":"),
        assignments:
          user?.sel_cust === "A" && assignments.length === 0
            ? assignmentIdArray.join(":")
            : assignments.join(":"),
        fromdate: fromdate,
        todate: todate,
        userid: user?.userid,
        paginationstatus: "N",
        assignment_status: "N",
        action: type,
        extension: extension.split("-")[0],
        page: 0,
        size: 10,
        customerid: 0,
        searchbyid: 0,
        searchtext: "",
      });
    }
  }, [extension]);

  const checkMultipleApprove = () => {
    if (transId?.length > 0) {
      // if (type === "Pending" || type === "Saved") {
      if (type === "Validated") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkMultipleSubmit = () => {
    if (transId?.length > 0) {
      if (type === "Pending") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkEdit = (val, status, flag, shiftcode) => {
    // console.log("flag", shiftcode);
    if (
      (status === "Pending" && shiftcode != 0) ||
      (status === "Validated" && shiftcode != 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkApprove = (val, status, shiftcode) => {
    if (status === "Validated" && shiftcode != 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (empData) {
      // Base date
      const baseDate = empData?.ScheduleDate;
      // Time to convert
      const time1 = empData?.RegularHrs ? empData?.RegularHrs : "00:00";
      const time2 = empData?.OTHrs ? empData?.OTHrs : "00:00";

      if (time1 === "08:00") {
        setHour(8);
      }else if(time1 === "09:00"){
       setHour(9);
      }else {
        setHour(24);
      }

      // Combined date and time
      const dateTime1 = `${baseDate}T${time1}:00.000`;
      const dateTime2 = `${baseDate}T${time2}:00.000`;

      let regularHrsTemp = new Date(dateTime1);
      regularHrsTemp.setSeconds(0);
      let otHrsTemp = new Date(dateTime2);
      otHrsTemp.setSeconds(0);

      setRegularHrs(regularHrsTemp);
      setTempRegularHrsChkTime(regularHrsTemp);
      setOtHrs(otHrsTemp);
      setTempOtHrsChkTime(otHrsTemp);
      setJustification(empData?.Justification);
    }
  }, [empData]);

  const { mutate: editSave, isLoading: editSaveLoading } = useMutation(
    (data) => api.post("CustomerAttendanceApproval_V1/Set", data),
    {
      onSuccess: (res) => {
        // setApproveModalOpen(false);
        // setEditModalOpen(false);
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success(`Record Saved Successfully`);
          let newData = JSON.parse(res.data.data);
          // console.log("newData",newData[0])
          let newArray = [...attendanceApprovalData];
          newArray?.splice(currentIndex, 1, newData[0]);
          setAttendanceApprovalData(newArray);

          // console.log("000000000000000000000000",attendanceApprovalData)
          // console.log("innnnnnnnnnnnnn",currentIndex)

          setEmpData();
          setValidateData();
          setRegularHrs();
          setTempRegularHrsChkTime();
          setOtHrs();
          setTempOtHrsChkTime();
          setJustification("");
          setDateAltered(false);
          // refetchData();
          setValidationMsg("");
        }
      },
    }
  );

  const ValiDate = () => {
    // console.log("empData.ApprovedOut", empData.ApprovedOut);

    let T1 = moment.duration(moment(empData.ApprovedOut).format("HH:mm"));
    let T2 = moment.duration(totalPerfHrs);
    // console.log("T1", T1);
    // console.log("T2", T2);

    let hours1 = T1.hours();
    let hours2 = T2.hours();
    // console.log("hours1", hours1);
    // console.log("hours2", hours2);

    let minutes1 = T1.minutes();
    let minutes2 = T2.minutes();
    // console.log("minutes1", minutes1);
    // console.log("minutes2", minutes2);

    let newHours = hours1 + hours2;
    let newMinutes = minutes1 + minutes2;

    let totalMinutes = newMinutes <= 60 ? newMinutes : newMinutes - 60;
    let totalHours = newMinutes <= 60 ? newHours : newHours + 1;
    // console.log("total", totalHours);
    // console.log("totalmmm", totalMinutes);

    setTimeout(() => {
      if (dateAltered === true) {
        if (justification !== "") {
          editSave({
            user_id: user?.userid,
            id: [JSON.parse(empData?.id)],
            action: "Validated",
            validatedBy_IP: ip,
            regularHrs: moment(regularHrs).format("HH:mm"),
            otHrs: moment(otHrs).format("HH:mm"),
            comments: justification,
            totalPerfHrs: totalPerfHrs,
            modifiedOut: totalPerfHrs,
          });
        } else {
          setValidationMsg("This Field Is Required");
        }
      } else {
        editSave({
          user_id: user?.userid,
          id: [JSON.parse(empData?.id)],
          action: "Validated",
          validatedBy_IP: ip,
          regularHrs: moment(regularHrs).format("HH:mm"),
          otHrs: moment(otHrs).format("HH:mm"),
          comments: justification,
          totalPerfHrs: totalPerfHrs,
          modifiedOut: `${hours}:${minutes}`,
        });
      }
    }, 500);
  };

  let tempOtHrs = moment.duration(moment(otHrs).format("HH:mm"));
  let tempRegularHrs = moment.duration(moment(regularHrs).format("HH:mm"));
  let minutes1 = tempOtHrs.asMinutes();
  let minutes2 = tempRegularHrs.asMinutes();
  let duration = moment.duration(minutes1 + minutes2, "minutes");
  let hours = Math.floor(duration.asHours());
  let minutes = duration.minutes();
  let time = moment().hours(hours).minutes(minutes);
  let totalPerfHrs = time.format("HH:mm");

  const disableHours = (hour) => {
    return hour > 8;
  };

  const disableMinutes = (minutes) => {
    if (minutes > 60) {
      return []; // Disable all minutes
    } else {
      return 60;
    }
  };

  // console.log("accessDetails", accessDetails);
  // console.log(
  //   "accessDetails",
  //   accessDetails?.find(
  //     (item) =>
  //       item.option_name === "Reports_AttendanceApprovalV1_Set" &&
  //       item.action_name === "Validated"
  //   ) !== undefined
  // );

  console.log("empData", attendanceApprovalData);

  return (
    <>
      <MessageModal
        messageModal={messageModal}
        setMessageModal={setMessageModal}
      />
      <JustificationModal
        JustificationModal={justificationModal}
        setJustificationModal={setJustificationModal}
        validationMsg={validationMsg}
        setJustification={setJustification}
        justification={justification}
        ValiDate={ValiDate}
      />
      <AttendanceDetailsModalV1
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        empData={empData}
        setEmpData={setEmpData}
        refetchData={refetchData}
        ip={ip}
      />
      <AttendanceDetailsModalAddNew
        AddNewModal={AddNewModal}
        setAddNewModal={setAddNewModal}
        empData={empData}
        setEmpData={setEmpData}
        refetchData={refetchData}
        ip={ip}
      />
      <AttendanceDetailsViewModalV1
        viewModalOpen={viewModalOpen}
        setViewModalOpen={setViewModalOpen}
        empData={empData}
        setEmpData={setEmpData}
        refetchData={refetchData}
        ip={ip}
      />
      <SingleApproveModal
        singleApproveModal={singleApproveModal}
        setSingleApproveModal={setSingleApproveModal}
        approvedId={approvedId}
        refetchData={refetchData}
        ip={ip}
      />
      <MultipleApproveModal
        multipleApproveModal={multipleApproveModal}
        setMultipleApproveModal={setMultipleApproveModal}
        transId={transId}
        refetchData={refetchData}
        ip={ip}
      />
      <RejectModal
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
        rejectId={rejectId}
        refetchData={refetchData}
        ip={ip}
      />
      <MultipleSubmitModal
        multipleSubmitModal={multipleSubmitModal}
        setMultipleSubmitModal={setMultipleSubmitModal}
        transId={transId}
        refetchData={refetchData}
        ip={ip}
      />
      {isLoading ? (
        <div
          style={{
            background: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: "9999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
        </div>
      ) : (
        <div className="mainBody2">
          <meta name="viewport" content="width=1600" />
          <div className="dashboardSection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="textStrip PageIndicator">
                    <h4>
                      Reports <i className="fa fa-angle-right"></i> Attendance
                      Approval
                    </h4>
                  </div>
                  {user?.sel_cust === "A" ? (
                    <ControlFilter2 period={false} />
                  ) : (
                    <ControlPanelFilterNew period={false} />
                  )}
                  {isLoading ? (
                    <div className="loaderSec">
                      <div className="loaderBox">
                        <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <>
                        <div className="guestAttendanceSec">
                          <div className="topBarStrip">
                            <div className="tableFilterSec">
                              {reduxData && (
                                <div className="row">
                                  <div className="col-xl-7 col-md-9">
                                    <div className="tblSearchBar tblSearchAttenApprBar">
                                      <SelectPicker
                                        data={
                                          newStatusFilter
                                            ? newStatusFilter?.map((item) => {
                                                return {
                                                  value: item?.action_name,
                                                  label: item?.action_name,
                                                };
                                              })
                                            : []
                                        }
                                        style={{ width: 224 }}
                                        className="attAprCatg1"
                                        placeholder="Select"
                                        value={type}
                                        onChange={(val) => {
                                          setType(val);
                                          setTransId([]);
                                          setSearchById(0);
                                          setSearchTerm("");
                                          setPage(0);
                                          if (val == null) {
                                            setType("all");
                                          }
                                        }}
                                        searchable={false}
                                        cleanable={false}
                                      />
                                      <SelectPicker
                                        data={searchBy}
                                        style={{ width: 224 }}
                                        placeholder="Search By"
                                        value={searchById}
                                        onChange={(value) => {
                                          setSearchById(value);
                                          if (value == null) {
                                            setSearchById(0);
                                            setSearchTerm("");
                                            setSearchButton(false);
                                          }
                                        }}
                                      />
                                      <input
                                        type="text"
                                        style={{ width: 224 }}
                                        className="form-control"
                                        name="searchTerm"
                                        placeholder="Search Here"
                                        onChange={(e) => {
                                          setSearchTerm(e.target.value);
                                        }}
                                        value={searchTerm}
                                        disabled={searchById > 0 ? false : true}
                                      />
                                      <button
                                        onClick={() => {
                                          setPage(0);
                                          setSearchButton((val) => !val);
                                        }}
                                      >
                                        <FaSearch />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-xl-5 col-md-3">
                                    <div className="tableFilterRightBoxCont">
                                      {attendanceApprovalData !== 0 ? (
                                        getFileLoading ? (
                                          <div className="LoaderFullScreenSec">
                                            <ImpulseSpinner
                                              backColor="#666"
                                              frontColor="#E4002B"
                                            />
                                          </div>
                                        ) : (
                                          <ul className="exportIconList">
                                            {accessDetails.find(
                                              (item) =>
                                                item.option_name ===
                                                "Reports_AttendanceApprovalV1_Download"
                                            ) !== undefined ? (
                                              <>
                                                {" "}
                                                <li>
                                                  <button
                                                    className="imgBtn"
                                                    onClick={() => {
                                                      setExtension(
                                                        `csv-${
                                                          Math.random() * 100
                                                        }`
                                                      );
                                                    }}
                                                    disabled={isLoading}
                                                  >
                                                    <img
                                                      src="../../../images/icons/user/icon_csv.svg"
                                                      alt="export"
                                                      height={30}
                                                      width={30}
                                                    />
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="imgBtn"
                                                    onClick={() => {
                                                      setExtension(
                                                        `xlsx-${
                                                          Math.random() * 100
                                                        }`
                                                      );
                                                    }}
                                                    disabled={isLoading}
                                                  >
                                                    <img
                                                      src="../../../images/icons/user/icon_excel.svg"
                                                      alt="export"
                                                      height={30}
                                                      width={30}
                                                    />
                                                  </button>
                                                </li>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </ul>
                                        )
                                      ) : (
                                        ""
                                      )}
                                      <div className="tableFilterSideBtns">
                                        {accessDetails?.find(
                                          (item) =>
                                            item.option_name ===
                                              "Reports_AttendanceApprovalV1_ManualSet" &&
                                            item.action_name === "Validated"
                                        ) !== undefined ? (
                                          <button
                                            // disabled={true}
                                            onClick={() => setAddNewModal(true)}
                                            // className={`btn2 bg-red`}
                                            className={`btn2`}
                                          >
                                            Add New
                                          </button>
                                        ) : (
                                          <></>
                                        )}

                                        {accessDetails?.find(
                                          (item) =>
                                            item.option_name ===
                                              "Reports_AttendanceApprovalV1_Set" &&
                                            item.action_name === "Validated"
                                        ) !== undefined ? (
                                          <button
                                            disabled={
                                              checkMultipleSubmit()
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              setMultipleSubmitModal(true)
                                            }
                                            className={`btn2 blackGrdClr ${
                                              checkMultipleSubmit()
                                                ? "disableBGBtn"
                                                : ""
                                            }`}
                                          >
                                            ValiDate
                                          </button>
                                        ) : (
                                          <></>
                                        )}

                                        {accessDetails?.find(
                                          (item) =>
                                            item.option_name ===
                                              "Reports_AttendanceApprovalV1_Set" &&
                                            item.action_name === "Approved"
                                        ) !== undefined ? (
                                          <button
                                            disabled={checkMultipleApprove()}
                                            onClick={() =>
                                              setMultipleApproveModal(true)
                                            }
                                            className={`btn2 greenGrdClr ${
                                              checkMultipleApprove()
                                                ? "disableBGBtn"
                                                : ""
                                            }`}
                                          >
                                            Approve
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="guestCheckboxTblResponsive">
                            {isLoading ? (
                              <div className="loaderSec">
                                <div className="loaderBox">
                                  <ImpulseSpinner
                                    backColor="#666"
                                    frontColor="#E4002B"
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {attendanceApprovalData === 0 ? (
                                  <>
                                    <h4 className="text-center not-found">
                                      No Records Available
                                    </h4>
                                  </>
                                ) : (
                                  <table className="guestCheckboxTbl guestCheckboxTblWithAction attendApprCheckboxTbl">
                                    <thead>
                                      <tr>
                                        {/* {type === "All" ||
                                        type === "Rejected" ||
                                        type === "Submitted" ? (
                                          <></>
                                        ) : ( */}
                                        <th>
                                          <Checkbox
                                            disabled={
                                              type === "All" ||
                                              type === "Rejected" ||
                                              type === "Approved" ||
                                              type === "Saved" ||
                                              type === "Edited&Approved"
                                                ? true
                                                : false
                                            }
                                            onChange={handleCheckAll}
                                            checked={
                                              allTransId?.length > 0 &&
                                              transId?.length ===
                                                allTransId?.length
                                                ? true
                                                : false
                                            }
                                          />
                                        </th>
                                        {/* )} */}

                                        <th>Sl.No.</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Location</th>
                                        <th>Schedule Date</th>
                                        <th>Post</th>
                                        <th>Rank</th>
                                        <th>Shift</th>
                                        <th style={{ textAlign: "right" }}>
                                          Shift Start
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          Shift End
                                        </th>

                                        {/* <th style={{ textAlign: "right" }}>
                                          Cont. Hrs
                                        </th> */}
                                        <th>Emp Code</th>
                                        <th>Emp Name</th>
                                        <th>Duty Date</th>

                                        <th style={{ textAlign: "right" }}>
                                          In
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          Out
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          Actual Hrs
                                        </th>
                                        <th style={{ textAlign: "right" }}>
                                          Rounded Hrs
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "right",
                                            background: "#ffffcc",
                                          }}
                                        >
                                          Regular Hrs
                                        </th>

                                        <th
                                          style={{
                                            textAlign: "center",
                                            background: "#ffffcc",
                                          }}
                                        >
                                          OT Hrs
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "center",
                                            background: "#ffffcc",
                                          }}
                                        >
                                          Total Hrs
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "center",
                                            background: "#ffffcc",
                                          }}
                                        >
                                          Justification
                                        </th>
                                        <th>Scheduled</th>
                                        <th>Edited</th>
                                        <th>Flag</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {attendanceApprovalData &&
                                        attendanceApprovalData.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className={`${
                                                  item.AttMarkout === "Y"
                                                    ? "bg-red"
                                                    : item.status ===
                                                      "Validated"
                                                    ? "bg-blue"
                                                    : item.status === "Approved"
                                                    ? "bg-green"
                                                    : ""
                                                }`}
                                                key={index}
                                              >
                                                {/* {type === "All" ||
                                                type === "Rejected" ||
                                                type === "Submitted" ? (
                                                  <></>
                                                ) : ( */}
                                                <td>
                                                  <Checkbox
                                                    disabled={
                                                      type === "All" ||
                                                      type === "Rejected" ||
                                                      type === "Approved" ||
                                                      type === "Saved" ||
                                                      type ===
                                                        "Edited&Approved" ||
                                                      (type === "Validated" &&
                                                        item.status ===
                                                          "Approved") ||
                                                      (type === "Pending" &&
                                                        item.status ===
                                                          "Validated")
                                                        ? true
                                                        : false
                                                    }
                                                    checked={transId?.includes(
                                                      item.id
                                                    )}
                                                    value={item.id}
                                                    onChange={singleCheck}
                                                  />
                                                </td>
                                                {/* )} */}
                                                <td>{`${
                                                  page * 10 + index + 1
                                                }`}</td>
                                                <td>{item.state}</td>
                                                <td>{item.city}</td>
                                                <Whisper
                                                  followCursor
                                                  placement="top"
                                                  speaker={
                                                    <Tooltip>
                                                      {item?.Address}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <td>
                                                    {item?.Address?.substring(
                                                      0,
                                                      8
                                                    ) + "..."}
                                                  </td>
                                                </Whisper>
                                                <td className="whiteSpaceNowrap">
                                                  {moment(
                                                    item.ScheduleDate
                                                  ).format("DD-MM-y")}
                                                </td>
                                                {/* <td>{item.post}</td> */}
                                                <Whisper
                                                  followCursor
                                                  placement="top"
                                                  speaker={
                                                    <Tooltip>
                                                      {item.Post}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <td>
                                                    {item.Post?.substring(
                                                      0,
                                                      12
                                                    ) + "..."}
                                                  </td>
                                                </Whisper>
                                                <td>{item?.Rank}</td>
                                                <td>{item?.shiftcode}</td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >{`${
                                                  item?.shiftstart?.split(
                                                    ":"
                                                  )[0]
                                                }:${
                                                  item?.shiftstart?.split(
                                                    ":"
                                                  )[1]
                                                }`}</td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >{`${
                                                  item?.shiftend?.split(":")[0]
                                                }:${
                                                  item?.shiftend?.split(":")[1]
                                                }`}</td>
                                                {/* <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {item.ContHrs}
                                                </td> */}
                                                <td>{item.EmployeeCode}</td>
                                                <Whisper
                                                  followCursor
                                                  placement="top"
                                                  speaker={
                                                    <Tooltip>
                                                      {item.EmployeeName}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <td>
                                                    {item.EmployeeName?.substring(
                                                      0,
                                                      8
                                                    ) + "..."}
                                                  </td>
                                                </Whisper>
                                                <td>{item?.DutyDate}</td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {moment(
                                                    item?.ApprovedIn
                                                  ).format("HH:mm")}
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {moment(
                                                    item?.ApprovedOut
                                                  ).format("HH:mm")}
                                                </td>
                                                {/* <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  
                                                  {item.ApprovedDutyHrs &&
                                                  item.ApprovedDutyHrs != ""
                                                    ? changeTimeFormat(
                                                        item.ApprovedDutyHrs
                                                      )
                                                    : "--:--"}
                                                </td> */}
                                                <td>{item?.TotalPerfHrs}</td>
                                                <td>{item?.RoundedHrs}</td>

                                                {editable &&
                                                item?.id === empData?.id ? (
                                                  <td>
                                                    {item?.RegularHrs == "09:00" ? (
                                                      <DatePicker
                                                        //disable hours in regular hours
                                                        disabledHours={(h) => {
                                                          setHour(
                                                            h === 9 ? h : 24
                                                          );
                                                          return h > 9;
                                                        }}
                                                        //disable minutes in regular hours
                                                        disabledMinutes={(
                                                          minutes
                                                        ) => {
                                                          return (
                                                            minutes >
                                                            (hour === 9
                                                              ? 0
                                                              : 60)
                                                          );
                                                        }}
                                                        ranges={[]}
                                                        placement="auto"
                                                        value={regularHrs} //new Date(moment())
                                                        onChange={(val) => {
                                                          setDateAltered(true);
                                                          setRegularHrs(val);
                                                          // OT hours will be enabled only if regular hours are 8 and if after entering OT hours user reduced the regular hours <8 then OT should become zero

                                                          let datepickermanage =
                                                            new Date(
                                                              `2023-05-17T00:00:00.000`
                                                            );

                                                            console.log("8888888888899",val.getHours())

                                                          if (
                                                            val.getHours() < 9
                                                          ) {
                                                            setOtHrs(
                                                              datepickermanage
                                                            );
                                                          }
                                                        }}
                                                        format="HH:mm"
                                                        cleanable={false}
                                                        editable={false}
                                                      />
                                                    ) : (
                                                      <DatePicker
                                                        //disable hours in regular hours
                                                        disabledHours={(h) => {
                                                          setHour(
                                                            h === 8 ? h : 24
                                                          );
                                                          return h > 8;
                                                        }}
                                                        //disable minutes in regular hours
                                                        disabledMinutes={(
                                                          minutes
                                                        ) => {
                                                          return (
                                                            minutes >
                                                            (hour === 8
                                                              ? 0
                                                              : 60)
                                                          );
                                                        }}
                                                        ranges={[]}
                                                        placement="auto"
                                                        value={regularHrs} //new Date(moment())
                                                        onChange={(val) => {
                                                          setDateAltered(true);
                                                          setRegularHrs(val);
                                                          // OT hours will be enabled only if regular hours are 8 and if after entering OT hours user reduced the regular hours <8 then OT should become zero

                                                          let datepickermanage =
                                                            new Date(
                                                              `2023-05-17T00:00:00.000`
                                                            );


                                                          if (
                                                            val.getHours() < 8
                                                          ) {
                                                            setOtHrs(
                                                              datepickermanage
                                                            );
                                                          }
                                                        }}
                                                        format="HH:mm"
                                                        cleanable={false}
                                                        editable={false}
                                                      />
                                                    )}
                                                  </td>
                                                ) : (
                                                  <td>{item.RegularHrs}</td>
                                                )}

                                                {editable &&
                                                item?.id === empData?.id ? (
                                                  <td>
                                                    {item?.RegularHrs == "09:00" ? (
                                                      <DatePicker
                                                        disabled={
                                                          hour === 9
                                                            ? false
                                                            : true
                                                        }
                                                        //disable hours in ot hours
                                                        disabledHours={(h) => {
                                                          setHourTwo(
                                                            h === 9 ? h : 24
                                                          );
                                                          return h > 9;
                                                        }}
                                                        //disable minutes in ot hours
                                                        disabledMinutes={(
                                                          minutes
                                                        ) => {
                                                          return (
                                                            minutes >
                                                            (hourTwo === 9
                                                              ? 0
                                                              : 60)
                                                          );
                                                        }}
                                                        ranges={[]}
                                                        placement="auto"
                                                        value={otHrs}
                                                        onChange={(val) => {
                                                          setDateAltered(true);
                                                          setOtHrs(val);
                                                        }}
                                                        format="HH:mm"
                                                        cleanable={false}
                                                        editable={false}
                                                      />
                                                    ) : (
                                                      <DatePicker
                                                        disabled={
                                                          hour === 8
                                                            ? false
                                                            : true
                                                        }
                                                        //disable hours in ot hours
                                                        disabledHours={(h) => {
                                                          setHourTwo(
                                                            h === 8 ? h : 24
                                                          );
                                                          return h > 8;
                                                        }}
                                                        //disable minutes in ot hours
                                                        disabledMinutes={(
                                                          minutes
                                                        ) => {
                                                          return (
                                                            minutes >
                                                            (hourTwo === 8
                                                              ? 0
                                                              : 60)
                                                          );
                                                        }}
                                                        ranges={[]}
                                                        placement="auto"
                                                        value={otHrs}
                                                        onChange={(val) => {
                                                          setDateAltered(true);
                                                          setOtHrs(val);
                                                        }}
                                                        format="HH:mm"
                                                        cleanable={false}
                                                        editable={false}
                                                      />
                                                    )}
                                                  </td>
                                                ) : (
                                                  <td>{item.OTHrs}</td>
                                                )}

                                                {/* {editable &&
                                                item?.id ===
                                                  empData?.id ? (
                                                  <td className="lite-border">
                                                    {totalPerfHrs}
                                                  </td>
                                                ) : (
                                                  <td>{item.TotalPerfHrs}</td>
                                                )} */}

                                                {/* change on 08-07-2024  */}
                                                {editable &&
                                                item?.id === empData?.id ? (
                                                  <td className="lite-border">
                                                    {totalPerfHrs}
                                                  </td>
                                                ) : (
                                                  <td>{item.RoundedHrs}</td>
                                                )}

                                                {editable &&
                                                item?.id === empData?.id ? (
                                                  <td>
                                                    <Input
                                                      disabled={
                                                        otHrs ===
                                                          tempOtHrsChkTime &&
                                                        regularHrs ===
                                                          tempRegularHrsChkTime
                                                      }
                                                      as="textarea"
                                                      value={justification}
                                                      // onChange={(val) => {
                                                      //   setValidationMsg("");
                                                      //   setJustification(val);
                                                      // }}
                                                      onClick={() => {
                                                        setJustificationModal(
                                                          true
                                                        );
                                                        setValidationMsg(
                                                          "Write Your justifications Here"
                                                        );
                                                      }}
                                                      rows={3}
                                                      placeholder="Write Your justifications Here"
                                                    />
                                                    {/* <span className="text-danger">
                                                      {validationMsg}
                                                    </span> */}
                                                  </td>
                                                ) : (
                                                  <td>{item?.Justification}</td>
                                                )}

                                                <td>{item?.scheduled}</td>
                                                <td>{item?.Edited}</td>
                                                <td>{item?.Flag}</td>
                                                <td>{item?.status}</td>
                                                <td className="actionAttAprBtn ">
                                                  {editable &&
                                                  item?.id === empData?.id ? (
                                                    <div>
                                                      <button
                                                        className="btn-red-2"
                                                        onClick={() => {
                                                          otHrs ===
                                                            tempOtHrsChkTime &&
                                                          regularHrs ===
                                                            tempRegularHrsChkTime
                                                            ? setEditable(false)
                                                            : // ValiDate()

                                                              setJustificationModal(
                                                                true
                                                              );
                                                          setValidationMsg(
                                                            "Please Write Your justifications Then Save"
                                                          );
                                                        }}
                                                      >
                                                        Save
                                                      </button>
                                                      {/* <button
                                                        onClick={setEditable(
                                                          false
                                                        )}
                                                      >
                                                        Cancel
                                                      </button> */}
                                                    </div>
                                                  ) : (
                                                    <>
                                                      {accessDetails?.find(
                                                        (item) =>
                                                          item.option_name ===
                                                            "Reports_AttendanceApprovalV1_Set" &&
                                                          item.action_name ===
                                                            "Validated"
                                                      ) !== undefined ? (
                                                        type === "Approved" ? (
                                                          <></>
                                                        ) : (
                                                          <button
                                                            className={
                                                              checkEdit(
                                                                item?.id,
                                                                item?.status,
                                                                item?.Flag,
                                                                item?.shiftcode
                                                              ) === true
                                                                ? "disableBtn"
                                                                : ""
                                                            }
                                                            disabled={checkEdit(
                                                              item?.id,
                                                              item?.status,
                                                              item?.Flag,
                                                              item?.shiftcode
                                                            )}
                                                            onClick={() => {
                                                              setCurrentIndex(
                                                                index
                                                              );
                                                              setEditable(true);
                                                              setEmpData(item);

                                                              // setEditModalOpen(
                                                              //   true
                                                              // );
                                                            }}
                                                          >
                                                            {checkEdit(
                                                              item?.id,
                                                              item?.status,
                                                              item?.Flag,
                                                              item?.shiftcode
                                                            ) === true ? (
                                                              <>
                                                                <img
                                                                  src="../../../../images/icons/attendance/edit_greyed.svg"
                                                                  alt="export_icon"
                                                                  width={20}
                                                                  height={20}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  src="../../../../images/icons/attendance/edit.svg"
                                                                  alt="export_icon"
                                                                  width={20}
                                                                  height={20}
                                                                />
                                                              </>
                                                            )}
                                                          </button>
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {/* {accessDetails?.find(
                                                    (item) =>
                                                      item.option_name ===
                                                        "Reports_AttendanceApprovalV1_Set" &&
                                                      item.action_name ===
                                                        "Rejected"
                                                  ) !== undefined ? (
                                                    <button
                                                      className={
                                                        checkReject(
                                                          item.id,
                                                          item.status
                                                        ) === true
                                                          ? "disableBtn"
                                                          : ""
                                                      }
                                                      disabled={checkReject(
                                                        item.id,
                                                        item.status
                                                      )}
                                                      onClick={() => {
                                                        setRejectId(item.id);
                                                        setRejectModal(true);
                                                      }}
                                                    >
                                                      {checkReject(
                                                        item.id,
                                                        item.status
                                                      ) === true ? (
                                                        <>
                                                          <img
                                                            src="../../../../images/icons/attendance/reject_greyed.svg"
                                                            alt="export_icon"
                                                            width={20}
                                                            height={20}
                                                          />
                                                        </>
                                                      ) : (
                                                        <>
                                                          <img
                                                            src="../../../../images/icons/attendance/reject.svg"
                                                            alt="export_icon"
                                                            width={20}
                                                            height={20}
                                                          />
                                                        </>
                                                      )}
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )} */}

                                                      <button
                                                        onClick={() => {
                                                          setEmpData(item);
                                                          setViewModalOpen(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src="../../../../images/icons/icon_view.svg"
                                                          alt="export_icon"
                                                          width={20}
                                                          height={20}
                                                        />
                                                      </button>

                                                      {accessDetails?.find(
                                                        (item) =>
                                                          item.option_name ===
                                                            "Reports_AttendanceApprovalV1_Set" &&
                                                          item.action_name ===
                                                            "Approved"
                                                      ) !== undefined ? (
                                                        type === "Approved" ? (
                                                          <></>
                                                        ) : (
                                                          <button
                                                            className={
                                                              checkApprove(
                                                                item?.id,
                                                                item?.status,
                                                                item?.shiftcode
                                                              ) === true
                                                                ? "disableBtn"
                                                                : ""
                                                            }
                                                            disabled={checkApprove(
                                                              item?.id,
                                                              item?.status,
                                                              item?.shiftcode
                                                            )}
                                                            onClick={() => {
                                                              setApprovedId(
                                                                item.id
                                                              );
                                                              setSingleApproveModal(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            {checkApprove(
                                                              item?.id,
                                                              item?.status,
                                                              item?.shiftcode
                                                            ) === true ? (
                                                              <>
                                                                <img
                                                                  src="../../../../images/icons/attendance/approve_greyed.svg"
                                                                  alt="export_icon"
                                                                  width={20}
                                                                  height={20}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  src="../../../../images/icons/attendance/approve.svg"
                                                                  alt="export_icon"
                                                                  width={20}
                                                                  height={20}
                                                                />
                                                              </>
                                                            )}
                                                          </button>
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan={2}>
                                          {(attendanceApprovalData?.length <
                                            11 &&
                                            Math.ceil(count / 10) > 1) ||
                                          true ? (
                                            <div className="paginationBox">
                                              <span
                                                onClick={() =>
                                                  handlePageChange("minus")
                                                }
                                              >
                                                <FaChevronLeft />
                                              </span>
                                              <span>{`Page ${
                                                page + 1
                                              } of ${Math.ceil(
                                                count / 10
                                              )}`}</span>
                                              <span
                                                onClick={() =>
                                                  handlePageChange("plus")
                                                }
                                              >
                                                <FaChevronRight />
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </td>

                                        <td colSpan={2}>
                                          {(attendanceApprovalData?.length <
                                            11 &&
                                            Math.ceil(count / 10) > 1) ||
                                          true ? (
                                            <div className="black">
                                              <span>{` ${
                                                page === 0
                                                  ? page + 1
                                                  : page *
                                                      attendanceApprovalData?.length +
                                                    1
                                              }- ${
                                                (page + 1) *
                                                attendanceApprovalData?.length
                                              } out of ${count}`}</span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </td>

                                        {/* 
                                        <td
                                          colSpan={
                                            type === "All" ||
                                            type === "Rejected" ||
                                            type === "Submitted"
                                              ? 6
                                              : 7
                                          }
                                          style={{
                                            textAlign: "right",
                                            paddingRight: "20px !important",
                                          }}
                                        >
                                          <span>
                                            <span>CONT.COUNT :</span>{" "}
                                            {contractData?.ContractedMp}
                                          </span>
                                        </td> */}

                                        {/* <td style={{ textAlign: "right" }}>
                                          <span>
                                            {" "}
                                            {contractData?.ContractedHrs}
                                          </span>
                                        </td> */}

                                        {/* <td
                                          colSpan={4}
                                          style={{ textAlign: "right" }}
                                        >
                                          <span>
                                            <span>PERF.COUNT :</span>{" "}
                                            {performedData?.PerformedCount}
                                          </span>
                                        </td> 
                                        <td style={{ textAlign: "right" }}>
                                          <span>
                                            {" "}
                                            {changeTimeFormat(
                                              performedData?.PerformedApprovedHrs
                                            )}
                                          </span>
                                        </td>*/}
                                        <td colSpan={24}></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SingleApproveModal = ({
  singleApproveModal,
  setSingleApproveModal,
  approvedId,
  refetchData,
  ip,
  transId,
}) => {
  const { user } = useAuth();
  const { mutate: singleApprove, isLoading: singleApproveLoading } =
    useMutation((data) => api.post("CustomerAttendanceApproval_V1/Set", data), {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success("Record Approved Successfully");
          setSingleApproveModal(false);
          refetchData();
        }
      },
    });

  const save = () => {
    singleApprove({
      user_id: user?.userid,
      id: [approvedId],
      action: "Approved",
      validatedBy_IP: ip,
      regularHrs: "",
      otHrs: "",
      comments: "",
      totalPerfHrs: "",
    });
  };

  return (
    <Modal
      className="modalFormSec attendApprlModalSmall modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={singleApproveModal}
      onClose={() => setSingleApproveModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          Record once approved cannot be altered. Are you sure you want to
          continue?
        </p>
        <div>
          <button
            onClick={() => {
              setSingleApproveModal(false);
            }}
            className="btn2 btnBlack"
          >
            No
          </button>
          {singleApproveLoading ? (
            <button className="btn2">
              <Loader />
            </button>
          ) : (
            <button className="btn2" onClick={save}>
              Yes
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MultipleApproveModal = ({
  multipleApproveModal,
  setMultipleApproveModal,
  transId,
  refetchData,
  ip,
}) => {
  const { user } = useAuth();

  const { mutate: multipleApprove, isLoading: multipleApproveLoading } =
    useMutation((data) => api.post("CustomerAttendanceApproval_V1/Set", data), {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success(`${transId?.length} Records Approved Successfully`);
          setMultipleApproveModal(false);
          refetchData();
        }
      },
    });

  const save = () => {
    multipleApprove({
      user_id: user?.userid,
      id: transId,
      action: "Approved",
      validatedBy_IP: ip,
      regularHrs: "",
      otHrs: "",
      comments: "",
      totalPerfHrs: "",
    });
  };

  return (
    <Modal
      className="modalFormSec attendApprlModalSmall modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={multipleApproveModal}
      onClose={() => setMultipleApproveModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          Records once approved cannot be altered. Are you sure you want to
          continue?
        </p>
        <div>
          <button
            onClick={() => {
              setMultipleApproveModal(false);
            }}
            className="btn2 btnBlack"
          >
            No
          </button>
          <button onClick={save} className="btn2">
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const RejectModal = ({
  rejectModal,
  setRejectModal,
  rejectId,
  refetchData,
  ip,
}) => {
  const { user } = useAuth();
  const { mutate: SingleReject, isLoading: singleRejectLoading } = useMutation(
    (data) => api.post("CustomerAttendanceApproval_V1/Set", data),
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success("Record Rejected Successfully");
          setRejectModal(false);
          refetchData();
        }
      },
    }
  );

  const save = () => {
    SingleReject({
      user_id: user?.userid,
      id: JSON.stringify(rejectId),
      action: "Rejected",
      modifiedBy_IP: ip,
    });
  };

  return (
    <Modal
      className="modalFormSec attendApprlModalSmall modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={rejectModal}
      onClose={() => setRejectModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          Record once rejected cannot be altered. Are you sure you want to
          continue?
        </p>
        <div>
          <button
            onClick={() => {
              setRejectModal(false);
            }}
            className="btn2 btnBlack"
          >
            No
          </button>
          <button onClick={save} className="btn2">
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MultipleSubmitModal = ({
  multipleSubmitModal,
  setMultipleSubmitModal,
  transId,
  refetchData,
  ip,
}) => {
  const { user } = useAuth();
  const { mutate: multipleSubmit, isLoading: multipleSubmitLoading } =
    useMutation((data) => api.post("CustomerAttendanceApproval_V1/Set", data), {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success(
            `${transId?.length} Records Submitted Successfully`
          );
          setMultipleSubmitModal(false);
          refetchData();
        }
      },
    });

  const save = () => {
    multipleSubmit(
      //   {
      //   user_id: user?.userid,
      //   id: transId.join(":"),
      //   action: "Submitted",
      //   modifiedBy_IP: ip,
      // }
      {
        user_id: 1,
        id: transId,
        action: "Validated",
        validatedBy_IP: "117.199.26.41",
        regularHrs: "",
        otHrs: "",
        comments: "",
        totalPerfHrs: "",
      }
    );
  };

  return (
    <Modal
      className="modalFormSec attendApprlModalSmall modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={multipleSubmitModal}
      onClose={() => setMultipleSubmitModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          Records once submitted cannot be altered. Are you sure you want to
          continue?
        </p>
        <div>
          <button
            onClick={() => {
              setMultipleSubmitModal(false);
            }}
            className="btn2 btnBlack"
          >
            No
          </button>
          <button onClick={save} className="btn2">
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MessageModal = ({ messageModal, setMessageModal }) => {
  return (
    <Modal
      className="modalFormSec attendApprlModalSmall attendApprovalModalSec modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={messageModal}
      onClose={() => setMessageModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          "Please authorize attendance by first of every month for payroll
          processing"
        </p>
        <div>
          <button
            onClick={() => {
              setMessageModal(false);
            }}
            className="btn2"
          >
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const JustificationModal = ({
  JustificationModal,
  setJustificationModal,
  validationMsg,
  setJustification,
  justification,
  ValiDate,
}) => {
  return (
    <Modal
      className="modalFormSec attendApprlModalSmall attendApprovalModalSec modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={JustificationModal}
      onClose={() => setJustificationModal(false)}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={30}
          height={30}
        />
        <h4>{validationMsg}</h4>

        <div>
          <Input
            className="new-input"
            as="textarea"
            value={justification}
            onChange={(val) => {
              // setValidationMsg("");
              setJustification(val);
            }}
            rows={3}
            placeholder="Write Your justifications Here"
          />
        </div>
        <div>
          <button
            onClick={() => {
              setJustificationModal(false);
              setJustification("");
            }}
            className="btn2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setJustificationModal(false);
              ValiDate();
            }}
            className="btn2"
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AttendanceApprovalV1;
